import axios, { AxiosError } from "axios";
import { useRouter } from "next/router";
import { useQuery } from "react-query";
import { login } from "~/lib/utils/auth";

export interface User {
  id: string;
  email: string;
  sub: string;
  phoneNumber: string;
  firstName: string;
  lastName: string;
  contactPreferences?: string[] | null;
  individualId?: null;
  isMember: boolean;
  partners?: string[];
  intercomUserHash: string;
  agreedToTos: string;
}

export const USER_QUERY_KEY = ["user"];

function isUserExpected(pathname: string): boolean {
  if (pathname.includes("/signup")) return false;
  return true;
}

export function useUser() {
  const router = useRouter();

  return useQuery<User | undefined, AxiosError>(
    USER_QUERY_KEY,
    async () => {
      try {
        const response = await axios.get<User>("/api/proxy/v1/users");

        return response.data;
      } catch (error) {
        if (axios.isAxiosError(error) && error?.response?.status === 401) {
          if (isUserExpected(location.pathname)) {
            login(router);
          }

          // NOTE: Ignoring unauthorized responses signup
          return undefined;
        }

        throw error;
      }
    },
    {
      useErrorBoundary(error) {
        if (error && axios.isAxiosError(error) && error.response?.status) {
          return error.response?.status >= 500;
        }

        return false;
      },
    }
  );
}
